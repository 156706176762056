<template>
  <div>
    <commonPageBanner></commonPageBanner>

    <div class="content_area">
      <searchCompany
        class="search-wrap"
        v-model="queryParams.name"
        @search="_search"
        placeTitle="请输入企业名称或用户名"
      ></searchCompany>

      <!-- <filterSearch
        @handleSearch="_handleSearch"
        style="margin-bottom: 24px"
      ></filterSearch> -->
      <div class="wrap">
        <div v-if="MemberList.length > 0">
          <memberItem
            v-for="(item, i) in MemberList"
            :key="i"
            :membermsg="item"
            :class="{ is_right: (i + 1) % 4 == 0 }"
            class="infinite-list-item"
          ></memberItem>
        </div>
        <isAllDataTip v-if="total!=0 && total==MemberList.length"></isAllDataTip>
        <isLoadingTip v-if="loading"></isLoadingTip>
        <noDataImg v-if="MemberList.length == 0 && !loading" />
      </div>
    </div>
  </div>
</template>

<script>
import commonPageBanner from "~tcb/components/layouts/pageBanner";
import searchCompany from "~tcb/components/company/unit/searchCompany";
import filterSearch from "~tcb/components/common/filterSearch";
import memberItem from "~tcb/components/company/member/memberItem";
import noDataImg from "@/baseComponents/noDataImg";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
export default {
  metaInfo: {
    title: "联系人列表",
  },
  components: {
    commonPageBanner,
    searchCompany,
    filterSearch,
    memberItem,
    isLoadingTip,
    isAllDataTip,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      MemberList: [],
      loading: false,
      queryParams: {
        name: "", //公司名字
        city_id: "", //城市id
        company_credit: "", /// 信用评级：1-AAA，2-AA，3-A  
        genre_ids: "", // //分类：1-理事单位，2-监事单位，3-会长单位，4-会员单位
      },
    };
  },
  mounted() {
    this.getList(1)
    window.addEventListener('scroll', this.scrollEvent,false);
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollEvent,false);
  },
  methods: {
    async getList(type) { //type 1 条件查询  type 2 触底出发 
      // 防止多次出发 请求事件
      window.removeEventListener('scroll', this.scrollEvent,false);
      this.loading = true;
      let params = {};
      for (var key in this.queryParams) {
        params[key] = this.queryParams[key];
      }
      params.start = this.currentPage - 1;
      params.limit = 12;
      params.certificate = 19;
      params.company_source =  this.PJSource;
      let res = await this.$store.dispatch("API_company/getMemberList", params);
      if (res.success) {
        this.loading = false;
        this.total = res.total;
        if(type==2){
          this.MemberList.push(...res.data)
        }else if(type==1){
          this.MemberList = res.data;
        }
      }
    },
    scrollEvent(){
        if (document.documentElement.scrollTop + document.documentElement.clientHeight   >= document.body.scrollHeight- 354) {
            if(this.total!=this.MemberList.length){
              this.currentPage++
              this.getList(2)
              // 防止多次出发 请求事件
              window.addEventListener('scroll', this.scrollEvent,false);
            }
        }
    },
    //
    _handleSearch(query) {
      this.queryParams.city_id = query.city; //城市id
      this.queryParams.company_credit = query.credit; // 信用评级：1-AAA，2-AA，3-A
      this.queryParams.genre_ids = query.unitType; //分类：1-理事单位，2-监事单位，3-会长单位，4-会员单位
      this.currentPage=1
      this.getList(1);
    },
    _search() {
      this.currentPage=1
      this.getList(1);
    },
  },
};
</script>
<style scoped lang="less">
.is_right {
  margin-right: 0;
}
.search-wrap {
  text-align: right;
  margin-bottom: 16px;
}
.member_box {
  display: flex;
  flex-wrap: wrap;
  background-color: "#fafafa";
}
.wrap{
  min-height: 300px;
  position: relative;
}
</style>